import {
  reactive,ref
} from 'vue-demi';
import {
  createStore
} from 'vuex'
import {
  copyArrayOnly
} from '@/hooks/tools';
export default createStore({
  state: {
    searchEle: reactive({
      LinesPerPage: 100,
      Page: 1,
      SortFieldsName: '',
      SortDirection: -1
    }),
    netloading: 0,

    barcodeCount: 0,

    onTheWayDoneType:'',

    salesOnTheWayDoneType:'',

    windowHistory:[],

  },
  mutations: {
    clearState(state) {
      state.loginInitializeData = null;
    },
    clearToken(state){
      if(state?.loginInitializeData){
        state.loginInitializeData.rdsCode = ''
      }
      
    },
    updateBarcodeCount(state){
      const storage = window.localStorage;
      const user = state.loginInitializeData.userId
      if (JSON.parse(storage.getItem('BarStoreList' + user))) {
        state.barcodeCount = JSON.parse(storage.getItem('BarStoreList' + user)).length
      }
      else {
        state.barcodeCount = 0
      }
    },
    updateBarcodeCountDefi(state,newCount){
      state.barcodeCount = newCount
    },

    alterOnTheWayDoneSearchType(state,direc){
      state.onTheWayDoneType = direc
    },
    alterSalesOnTheWayDoneSearchType(state,direc){
      state.salesOnTheWayDoneType = direc
    },

    SaveDatas(state, datas) {
      state.loginInitializeData = null;
      state.loginInitializeData = datas;
      let a = copyArrayOnly(state.loginInitializeData.ActiveWindow)
      state.loginInitializeData.ActiveWindow.splice(0)
      setTimeout(() => {
        state.loginInitializeData.ActiveWindow.push.apply(state.loginInitializeData.ActiveWindow, a)
        state.windowHistory.splice(0)
        state.windowHistory.push(state.loginInitializeData.ShowWindow[0])
      }, 1000);
    },

    SaveDatasOnly(state, datas) {
      state.loginInitializeData.authList = datas.authList;
      state.loginInitializeData.docTableElementManage = datas.docTableElementManage;
      state.loginInitializeData.filterList = datas.filterList;
      state.loginInitializeData.listForValidations = datas.listForValidations;
      state.loginInitializeData.mainManuForShow = datas.mainManuForShow;
      state.loginInitializeData.pageNameManage = datas.pageNameManage;
    },

    turnOnLoading(state) {
      state.netloading ++;
    },
    turnOffLoading(state) {
      setTimeout(() => {
        state.netloading --;
      }, 100);
    },
    //注册成功后向中心发射注册信息，以便登录页面可以填充信息
    fillRegis(state, datas) {
      state.regisData = datas
    },

    GoFunctionWindow(state, keys) {
      let key1 = keys[0];
      let key2 = keys[1];
      let a = state.loginInitializeData.mainManuForShow[key1].viewLists[key2].viewCode;

      const b = state.windowHistory.indexOf(a)
      if(b > -1){
        state.windowHistory.splice(b,1)
      }
      state.windowHistory.push(a)



      state.loginInitializeData.ShowWindow.splice(0, 1, a);
      if (state.loginInitializeData.ActiveWindow.indexOf(a) == -1) {
        state.loginInitializeData.ActiveWindow.unshift(a)
      }
    },

    GoFunctionWindowWithCode(state, code) {

      const b = state.windowHistory.indexOf(code)
      if(b > -1){
        state.windowHistory.splice(b,1)
      }
      state.windowHistory.push(code)

      state.loginInitializeData.ShowWindow.splice(0, 1, code);
      if (state.loginInitializeData.ActiveWindow.indexOf(code) == -1) {
        state.loginInitializeData.ActiveWindow.push(code)
      }
    },

    DeleteActiveWindow(state, key) {

      let c = state.loginInitializeData.ActiveWindow[key]

      const b = state.windowHistory.indexOf(c)

      if(b > -1){
        state.windowHistory.splice(b,1)
      }

      if(state.windowHistory.length == 0){
        state.loginInitializeData.ShowWindow.splice(0, 1)
      }
      else{
        let a = state.windowHistory[state.windowHistory.length - 1]
        state.loginInitializeData.ShowWindow.splice(0, 1, a)
      }


      state.loginInitializeData.ActiveWindow.splice(key, 1)
    },


    AlterShowWindow(state, key) {
      let a = state.loginInitializeData.ActiveWindow[key]
      const b = state.windowHistory.indexOf(a)
      if(b > -1){
        state.windowHistory.splice(b,1)
      }
      state.windowHistory.push(a)


      state.loginInitializeData.ShowWindow.splice(0, 1, a)
    },

    CloseActiveWindow(state,code){

      const b = state.windowHistory.indexOf(code)
      if(b > -1){
        state.windowHistory.splice(b,1)
      }

      const length1 = state.loginInitializeData.ActiveWindow.length
      for(let i = length1 - 1;i>=0;i--){
        if(state.loginInitializeData.ActiveWindow[i] == code){
          state.loginInitializeData.ActiveWindow.splice(i,1)
        }
      }
    },

    equalizeShowAndActive(state) {
      for(let item of state.loginInitializeData.ActiveWindow){
        const b = state.windowHistory.indexOf(item)
        if(b > -1){
          state.windowHistory.splice(b,1)
        }
      }
      state.windowHistory.push(state.loginInitializeData.ShowWindow[0])

      state.loginInitializeData.ActiveWindow.splice(0)
      state.loginInitializeData.ActiveWindow.push(state.loginInitializeData.ShowWindow[0])
    },

    clearActive(state) {
      state.windowHistory.splice(0)

      state.loginInitializeData.ActiveWindow.splice(0)
    },

    modifysearchEle(state, content) {
      state.searchEle[content.keyName] = content.valueName
    },
    // refreshShowWindow(state) {
    //   let a = state.loginInitializeData.ShowWindow[0]
    //   state.loginInitializeData.ShowWindow.splice(0, 1, a)
    // },
    refreshShowWindow(state) {
      let a = state.loginInitializeData.ShowWindow[0]
      state.loginInitializeData.ShowWindow.splice(0, 1)
      setTimeout(() => {
        state.loginInitializeData.ShowWindow.push(a)
      }, 200);
    },

    refreshShowWindowReal(state, key) {
      let a = state.loginInitializeData.ActiveWindow[key]
      state.loginInitializeData.ActiveWindow.splice(key, 1)
      setTimeout(() => {
        state.loginInitializeData.ActiveWindow.push(a)
      }, 200);
    },

    refreshAllShowWindowReal() {
      let a = copyArrayOnly(state.loginInitializeData.ActiveWindow)
      state.loginInitializeData.ActiveWindow.splice(0)
      setTimeout(() => {
        state.loginInitializeData.ActiveWindow.push.apply(state.loginInitializeData.ActiveWindow, a)
      }, 1000);
    },
  },
  actions: {},
  modules: {}
})